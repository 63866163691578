import { infoToast } from '../../../components/common/Toast';
import * as apiService from '../../api/student/api.service';
import { defaultReturn } from '../../../util/newStudent';
import { cleanCache } from '../../../util/common/appUtil';
import { unauthenticatedRequest } from '../../api/newStudent/apiStudent.service';

export interface IDataRecover {
  userType: string | undefined;
  login: string;
}

export interface IDataLogin {
  login?: string;
  password?: string;
  userType?: number;
  masterAccess?: string | null;
}

export interface IDataRegister {
  login: string | null;
  newPassword: string;
  passwordConfirmation: string;
}

export interface IDataUpdate {
  newPassword: string;
  passwordConfirmation: string;
  token: string;
}

interface IDataFirstAccess {
  login?: string | null;
  userType: number;
}

const authNewStudentService = {
  async authenticate(data: IDataLogin) {
    localStorage.clear();
    return await apiService.unauthenticatedRequest(
      `/new/sign-in`,
      'POST',
      data,
    );
  },

  async refreshToken() {
    localStorage.setItem('lastRefreshToken', new Date().getTime().toString());

    const response = await apiService.authenticatedRequest(
      '/new/refresh-token',
      'POST',
    );

    if (response?.result) {
      authNewStudentService.setLoggedUser(response.data.access_token);
      localStorage.setItem('userStudent', response.data.access_token);
      return response;
    }
  },

  async rememberPassword(dataRecover: IDataRecover) {
    const request = await unauthenticatedRequest(
      `/remember-password`,
      'POST',
      dataRecover,
    );

    return defaultReturn(request);
  },

  async updatePassword(dataUpdate: IDataUpdate) {
    try {
      const request = await unauthenticatedRequest(
        '/recover-password',
        'POST',
        dataUpdate,
      );
      return defaultReturn(request);
    } catch (error) {
      throw new Error(error.message);
    }
  },

  async createFirstPassword(data: IDataRegister): Promise<{
    data: object;
    message: string[];
    result: boolean;
  }> {
    const request = await apiService.unauthenticatedRequest(
      '/student/create-password-first-login',
      'POST',
      data,
    );

    return defaultReturn(request);
  },

  async firstAccess(data: IDataFirstAccess): Promise<{
    data: {
      firstAccess: boolean;
    };
    message: string[];
    result: boolean;
  }> {
    const response = await apiService.unauthenticatedRequest(
      `/student/check-first-login`,
      'POST',
      data,
    );
    if (!response.result) return response;

    return defaultReturn(response);
  },

  setLoggedUser(data: string) {
    localStorage.setItem('userStudent', data);
    localStorage.setItem('lastRefreshToken', new Date().getTime().toString());
  },

  async getLoggedUser() {
    const lastRefresh = localStorage.getItem('lastRefreshToken');
    if (lastRefresh && +lastRefresh + 1000 * 5400 < new Date().getTime()) {
      const request = await apiService.authenticatedRequest(
        '/validate-token',
        'GET',
      );
      if (request && request.data.valid) {
        return localStorage.getItem('userStudent');
      }
    }
    return localStorage.getItem('userStudent');
  },

  cleanLoggerUser(isExpired: boolean = false) {
    if (isExpired) {
      infoToast('Sua sessão expirou, faça login novamente.');
    }

    setTimeout(() => {
      localStorage.clear();
      cleanCache();

      return (window.location.href = '/new/aluno');
    }, 1000);
  },
};

export default authNewStudentService;
