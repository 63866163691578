import React, { Dispatch, SetStateAction, useContext, useState } from 'react';

import * as S from './styles';

import { Formik } from 'formik';
import * as Yup from 'yup';

import { ButtonSimple } from '../../../../../components/newStudent/Buttons';
import FieldFormik from '../../../../../components/newStudent/FieldFormik';
import { authNewStudentService } from '../../../../../services/auth';
import {
  INewStudentProvider,
  NewStudentProviderContext,
} from '../../../../../components/newStudent/ProviderNewStudent';
import Divider from '../../../../../components/common/Divider';
import { userTypeStudent } from '../../../../../variables';

interface Props {
  setRecovery: Dispatch<SetStateAction<boolean>>;
}

export default function RecoverPassword({ setRecovery }: Props) {
  const { setIsLoading } = useContext(
    NewStudentProviderContext,
  ) as INewStudentProvider;

  const [successSend, setSuccessSend] = useState(false);
  const validationSchema = Yup.object({
    cpf: Yup.string(),
    email: Yup.string().email(),
  });

  const initialValues = {
    cpf: '',
    email: '',
  };

  const handleSubmit = (values: { cpf: string; email: string }) => {
    setIsLoading(true);
    const fetch = async () => {
      const request = await authNewStudentService.rememberPassword({
        login: values.cpf ? values.cpf : values.email,
        userType: userTypeStudent,
      });

      if (request.result) {
        setSuccessSend(true);
      }
      return setIsLoading(false);
    };
    fetch();
  };

  return (
    <S.Container>
      {successSend ? (
        <S.ContainerSuccess>
          <S.Title>Senha Recuperada com sucesso!</S.Title>
          <S.SubTitle>
            Confira o recebimento em seu e-mail de cadastro para a criação de
            uma nova senha.
          </S.SubTitle>
          <S.ButtomContainer>
            <ButtonSimple
              type="submit"
              color="primary"
              font="h4"
              heightSize="large"
              onClick={() => setRecovery(false)}
            >
              Faça o Login
            </ButtonSimple>
          </S.ButtomContainer>
        </S.ContainerSuccess>
      ) : (
        <>
          <S.ContainerTitle>
            <S.Title>Recuperar Senha</S.Title>
            <S.SubTitle>Insira seu CPF ou e-mail para recuperar.</S.SubTitle>
          </S.ContainerTitle>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {/** Na chamada da função, pegue o errors e o touched */}
            {({ handleChange }) => (
              <S.StyledForm>
                <div>
                  <FieldFormik
                    name={'cpf'}
                    type={'string'}
                    widthSize="fullWidth"
                    heightSize="large"
                    placeholder="Informe seu CPF"
                    onChange={handleChange}
                  />
                  <Divider>ou</Divider>
                  <FieldFormik
                    name={'email'}
                    type={'string'}
                    widthSize="fullWidth"
                    heightSize="large"
                    placeholder="Informe seu e-mail"
                    onChange={handleChange}
                  />
                </div>
                <S.Remember>
                  <S.A onClick={() => setRecovery(false)}>
                    <p>
                      <strong>Espera!</strong> Acabei de lembrar minha senha!
                    </p>
                  </S.A>
                </S.Remember>
                <S.ButtomContainer>
                  <ButtonSimple
                    type="submit"
                    color="primary"
                    font="h4"
                    heightSize="large"
                  >
                    Enviar
                  </ButtonSimple>
                </S.ButtomContainer>
              </S.StyledForm>
            )}
          </Formik>
        </>
      )}
    </S.Container>
  );
}
